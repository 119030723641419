exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-schedule-jsx": () => import("./../../../src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-venue-jsx": () => import("./../../../src/pages/venue.jsx" /* webpackChunkName: "component---src-pages-venue-jsx" */),
  "component---src-pages-workshops-jsx": () => import("./../../../src/pages/workshops.jsx" /* webpackChunkName: "component---src-pages-workshops-jsx" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-call-for-volunteers-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/home/runner/work/kcddenmark.dk/kcddenmark.dk/content/static-pages/call-for-volunteers.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-call-for-volunteers-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-data-privacy-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/home/runner/work/kcddenmark.dk/kcddenmark.dk/content/static-pages/data-privacy.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-data-privacy-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-mission-statement-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/home/runner/work/kcddenmark.dk/kcddenmark.dk/content/static-pages/mission-statement.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-mission-statement-md" */),
  "component---src-templates-static-jsx-content-file-path-content-static-pages-shuttle-bus-md": () => import("./../../../src/templates/static.jsx?__contentFilePath=/home/runner/work/kcddenmark.dk/kcddenmark.dk/content/static-pages/shuttle-bus.md" /* webpackChunkName: "component---src-templates-static-jsx-content-file-path-content-static-pages-shuttle-bus-md" */)
}

